<template>
	<div :class="{'index-wrapper':true, 'font-en': lang!=='zh-cn'}">
        <div v-for="(item,index) in pageitems" :key="index">
            <div v-if="item.item_type == 'video'" class="container wow fadeInDown">
				<video 
				class="serve-panel-photo"
				:src="item.pic[0]" 
				controls></video>
			</div>
            <div v-if="item.item_type == 'float'" class="about-first">
                <div class="container">
                    <div class="about-card wow fadeInDown">
                        <div class="about-photo">
                            <img :src="item.pic[0]" alt="" />
                        </div>
                        <div class="flex_bd">
                            <h2>{{item.title}}</h2>
                            <p>{{ item.titile_comment }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="item.item_type == 'onlytitle'" class="serve-panel wow fadeInDown" >
                <div class="section-head text-center">
                    <div class="h2">{{item.title}}</div>					
                </div>
                <!-- <h2>{{item.title}}</h2> -->
                <!-- <p>{{ item.titile_comment }}</p> -->
                <div class="service-card flex_bd" v-if="!!item.titile_comment">
                    <div class="service-cell">
                        <!-- <div class="service-cell_hd">{{item.content.split(",")[0]}}</div> -->
                        <ul class="service-list">
                            <li v-for="(ite, idx) in item.titile_comment.split(';')" :key="idx">
                                <!-- <span>0{{ idx }}</span> -->
                                {{ ite }}
                            </li>										
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div v-if="item.item_type == 'left'" class="index-list-card wow fadeInDown">
                <div class="list-card-photo">
                    <img :src="item.pic[0]" alt="" />
                </div>
                <div class="flex_bd">
                    <h3>{{item.title}}</h3>
                    <div v-html="item.titile_comment"></div>
                </div>
            </div> -->
            <div  v-if="item.item_type == 'left'" class="index-section index-style-e">
				<div class="container wow fadeInUp">
					<div class="maker-cell">
                        <div class="maker-photo" >
							<img :src="item.pic[0]" alt="" />
						</div>
						<div class="flex_bd" style="margin-left: 15px;">
							<div class="section-head ">
								<div class="h2">{{item.title}}</div>
								<div class="h4">{{item.titile_comment}}</div>
							</div>
							<ul class="maker-list" v-if="item.content&&item.content.length>0">
								<li v-for="(ite, idx) in item.content" :key="idx">
									<div class="cell-title">{{ lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
									<div class="cell-desc">{{ lang=='zh-cn'?ite.desc:ite.desc_en }}</div>
								</li>								
							</ul>
						</div>						
					</div>
				</div>
			</div>
            <div  v-if="item.item_type == 'right'" class="index-section index-style-e">
				<div class="container wow fadeInUp">
					<div class="maker-cell">
						<div class="flex_bd">
							<div class="section-head ">
								<div class="h2">{{item.title}}</div>
								<div class="h4">{{item.titile_comment}}</div>
							</div>
							<ul class="maker-list" v-if="item.content&&item.content.length>0">
								<li v-for="(ite, idx) in item.content" :key="idx">
									<div class="cell-title">{{ lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
									<div class="cell-desc">{{ lang=='zh-cn'?ite.desc:ite.desc_en }}</div>
								</li>								
							</ul>
						</div>
						<div class="maker-photo">
							<img :src="item.pic[0]" alt="" />
						</div>
					</div>
				</div>
			</div>
            <!-- <div v-if="item.item_type == 'right'" class="service-row wow fadeInDown">
                <div class="service-photo" v-if="item.pic&&item.pic.length>0">
                    <img :src="item.pic[0]" alt="" />
                </div>
                <div class="flex_bd">
                    <div class="service-card">
                        <div class="service-grid">
                            <div class="tit">{{item.title}}</div>
                            <div v-if="item.titile_comment" class="desc">{{item.titile_comment}}</div>
                        </div>
                        <div class="service-cell" v-if="item.content&&item.content.length>0">
                            <div class="service-cell_hd">{{item.content[0].ltitle}}</div>
                            <ul class="service-list" v-if="lang=='zh-cn'">
                                <li v-for="(ite, idx) in item.content[0].desc.split(';')" :key="idx">
                                    <span v-if="idx>0">0{{ idx }}</span>{{ ite }}
                                </li>										
                            </ul>
                            <ul class="service-list" v-else>
                                <li v-for="(ite, idx) in item.content[0].desc_en.split(';')" :key="idx">
                                    <span v-if="idx>0">0{{ idx }}</span>{{ ite }}
                                </li>										
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="container wow fadeInUp" v-if="item.item_type == 'zx-card'">
                <div class="section-head ">
                    <div class="h2">{{item.title}}</div>
                    <p>{{item.titile_comment}}</p>
                </div>
                <!-- <div class="section-head text-center">
                    <div class="h2">{{item.title}}</div>
                    <p>{{item.titile_comment}}</p>
                </div> -->
                <div class="serv-tab-body" >
                    <el-row :gutter="24">
                        <el-col :xs="12" :sm="12" :md="24/item.content.length" v-for="(ite,idx) in item.content" :key="idx">
                            <div class="zx-card">
                                <div class="cell-icon">
                                    <img :src="ite.pic" alt="" />
                                </div>
                                <div class="cell-title">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
                                <div class="cell-desc">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
                            </div>
                        </el-col>                        
                    </el-row>
                </div>
            </div>
            <div class="index-section index-style-a " v-if="item.item_type == 'custom-panel'">
				<div class="container wow fadeInUp">
					<div class="section-head ">
						<div class="h2">{{item.title}} 
                            <!-- <span>标题</span> -->
                        </div>
						<p>{{item.titile_comment}}</p>
					</div>
					<el-row :gutter="24">
						<el-col :xs="12" :sm="6" :md="6" v-for="(ite,idx) in item.content" :key="idx">
							<div class="custom-panel hover-up">
								<div class="cell-icon">
									<img :src="ite.pic" alt="" />
								</div>
								<div class="cell-title">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
								<div class="cell-desc">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
							</div>
						</el-col>
						
					</el-row>
				</div>
			</div>
            <div v-if="item.item_type == 'hezuo'" class="about-section">
                <div class="section-title wow fadeInDown">{{item.title}}</div>
                <div class="container wow fadeInDown">
                    <el-row :gutter="20">
                        <el-col  v-for="(ite,idx) in item.content" :key="idx" :xs="12" :span="24/item.content.length" >
                            <div class="partner-card hover-up">
                                <img :src="ite.pic" alt="" />
                                <div class="p-label">{{ lang=='zh-cn'?ite.ltitle:ite.ltitle_en }}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div v-if="item.item_type == 'Three_column'" class="container">
                <div class="section-head ">
                    <div class="h2">{{item.title}}</div>
                    <p>{{item.titile_comment}}</p>
                </div>
                <el-row :gutter="20">
                    <el-col v-for="(ite,idx) in item.content" :key="idx" :xs="12" :span="8">
                        <div class="ena-card hover-up">
                            <div class="ena-photo">
                                <img :src="ite.pic" alt="点击查看详情" />
                                <!-- <div class="ena-icon">
                                    <img src="../../src/assets/image/icon-3.png" alt="" />
                                    <div class="e-label">点击查看</div>
                                </div> -->
                            </div>
                            <div class="ena-grid">
                                <div class="tt">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
                                <div class="desc">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
                            </div>
                        </div>
                    </el-col>		
                </el-row>
            </div>
            <div v-if="item.item_type == 'four_column'"  class="index-section index-style-a ">
                <div  class="container wow fadeInUp">
                    <div class="section-head ">
                        <div class="h2">{{item.title}}</div>
                        <p>{{item.titile_comment}}</p>
                    </div>
                    <el-row :gutter="24">
                        <el-col  v-for="(ite,idx) in item.content" :key="idx"  :xs="12" :sm="6" :md="6">
                            <div class="custom-panel hover-up">
                                <div class="cell-icon">
                                    <img :src="ite.pic" alt="" />
                                </div>
                                <div class="cell-title">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
                                <div class="cell-desc">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
                            </div>
                        </el-col>							
                    </el-row>
                </div>
            </div>
            <div v-if="item.item_type == 'product_list'" class="index-section">
                <div class="index-section index-style-b">
                    <div class="container wow fadeInUp">
                        <div class="section-head flex">
                            <div class="flex_bd">
                                <div class="h2">{{item.title}}</div>
                                <p>{{item.titile_comment}}</p>
                            </div>
                            <!-- <el-button v-if="lang==='zh-cn'" type="info" plain>浏览完整目录</el-button>
                            <el-button v-else type="info" plain>Browse the full catalog</el-button> -->
                        </div>
                        <el-row :gutter="24" type="flex" class="pack-row">
                            <el-col :xs="12" :sm="8" :md="8" :lg="6" v-for="(ite,idx) in item.content" :key="idx">
                                <div class="pack-card hover-up"  @click="clickit(idx)">
                                    <div class="pack-photo scale">
                                        <img :src="ite.pic.split(',')[0]" alt="" />
                                    </div>
                                    <div class="pack-grid">
                                        <div class="cell-title">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
                                        <div class="cell-desc">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
                                    </div>
                                </div>
                            </el-col>
                            <!-- <el-col :xs="12" :sm="8" :md="8" :lg="6">
                                <div v-if="lang==='zh-cn'" class="pack-more-card">
                                    <div class="p-title">在找别的东西吗?我们可以帮忙。</div>
                                    <el-button type="info" plain>请求定制报价</el-button>
                                </div>
                                <div v-else class="pack-more-card">
                                    <div class="p-title">Looking for something else? We can help.</div>
                                    <el-button type="info" plain>Request a custom quote</el-button>
                                </div>
                            </el-col> -->
                        </el-row>
                    </div>
                </div>
            </div>
            <div v-if="item.item_type == 'case_swiper'" class="index-section">
                <div class="index-section index-style-c">
                    <div class="container wow fadeInUp">
                        <div class="section-head flex">
                            <div class="flex_bd">
                                <div class="h2">{{item.title}}</div>
                                <p>{{item.titile_comment}}</p>
                            </div>
                            <div class="k-arrow-box">
                                <span class="k-arrow c--prev el-icon-arrow-left"></span>
                                <span class="k-arrow c--next el-icon-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                    <swiper class="case-swiper" :options="swiperOptionThumbs" ref="caseThumbs">
                        <swiper-slide v-for="(ite,inde) in item.content" :key="inde">
                            <div class="case-card ">
                                <div class="case-photo scale"><img :src="ite.pic" alt="" /></div>
                                <div class="case-grid">
                                    <div class="cell-title">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
                                <div class="cell-desc">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="case-pagination"></div>
                </div>
            </div>
            <div v-if="item.item_type == 'two_column'" class="index-style-bg">
                <div class="index-section index-style-c">
                    <div class="container wow fadeInUp">
                        <div class="section-head ">
                            <div class="h2">{{item.title}}</div>
                            <p>{{item.titile_comment}}</p>
                        </div>
                        <el-row :gutter="24">
                            <el-col v-for="(ite,idx) in item.content" :key="idx" :xs="12" :sm="12">
                                <div class="continue-card hover-up">
                                    <img :src="ite.pic" alt="" />
                                    <div class="ct-grid">
                                        <div class="sm-label">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</div>
                                        <div class="ct-title">{{lang=='zh-cn'?ite.desc:ite.desc_en}}</div>
                                        <!-- <el-button class="btn btn-view">浏览产品</el-button> -->
                                    </div>
                                </div>
                            </el-col>								
                        </el-row>
                    </div>
                </div>
                <div class="circle-box ">
                    <img src="../assets/image/index/circle.png" alt="" />
                </div>
            </div>
            <div v-if="item.item_type == 'top'" class="container  wow fadeInDown">
                <div class="section-head ">
                    <div class="h2">{{item.title}}</div>					
                </div>
                <div class="serve-panel-photo"><img :src="item.pic[0]" alt="" /></div>
                <div class="serve-ruc">
                    <div >
                        <!-- <div class="h2"><span class="text-info">{{item.titile_comment}}</span></div> -->
                        <div class="service-card flex_bd">
                            <div class="service-cell">
                                <ul class="service-list">
                                    <li v-for="(ite, idx) in item.content" :key="idx">
                                        <div class="h2"> {{ lang=='zh-cn'?ite.ltitle:ite.ltitle_en }}
                                        </div>
                                        <p>
                                            {{ lang=='zh-cn'?ite.desc:ite.desc_en }}
                                        </p>
                                    </li>										
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="cell-desc" v-html="lang=='zh-cn'?item.content[0].desc:item.content[0].desc_en"></div> -->
                    </div>
                </div>
            </div>
            <div  v-if="item.item_type == 'tab'"  class="index-section index-style-d">
                <div class="container wow fadeInUp">
                    <div class="section-head text-center">
                        <div class="h2">{{item.title}}</div>
                        <p>{{item.titile_comment}}</p>							
                    </div>
                    <el-tabs v-model="tavName" class="serve-tabs" stretch>
                        <el-tab-pane v-for="(ite,idx) in item.content" :key="idx" :label="ite.title" name="a">
                            <div class="serv-tab-body">
                                <!-- <div v-html="ite.content"></div> -->
                                <div class="serve-panel-photo"><img :src="ite.pic" alt="" /></div>
                                <div class="serve-ruc">
                                    <div class="s-head">
                                        <div class="h2"><span class="text-info">{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</span></div>
                                        <div class="cell-desc" v-html="lang=='zh-cn'?ite.desc:ite.desc_en"></div>
                                    </div>
                                </div>                                    
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    
                </div>
            </div>           
            <div v-if="item.item_type == 'fankui'" class="index-section index-style-f">
                <div class="container wow fadeInUp">
                    <div class="section-head flex">
                        <div class="flex_bd">
                            <div class="h2">{{item.title}}</div>
                            <p>{{item.titile_comment}}</p>	
                            <!-- <div class="h2">看客户怎么说</div>
                            <p>不要让我们说的话影响你,从我们的顾客那里拿走!</p> -->
                        </div>
                        <div class="k-arrow-box">
                            <span class="k-arrow k--prev el-icon-arrow-left"></span>
                            <span class="k-arrow k--next el-icon-arrow-right"></span>
                        </div>
                    </div>
                </div>
                <swiper class="kehu-swiper wow fadeInDown" :options="kehuThumbs" ref="kehuThumbs">
                    <swiper-slide v-for="(ite,idx) in item.content" :key="idx">
                        <div class="kh-card">
                            <div class="kh-bd">
                                <div class="kh-content">{{ lang=='zh-cn'?ite.desc:ite.desc_en }}</div>
                                <div class="kh-raty">
                                    <span class="el-icon-star-on"></span>
                                    <span class="el-icon-star-on"></span>
                                    <span class="el-icon-star-on"></span>
                                    <span class="el-icon-star-on"></span>
                                    <span class="el-icon-star-on"></span>
                                </div>
                            </div>
                            <div class="kh-bar">
                                <img src="../assets/image/index/jasd.png" alt="" />
                                <div class="flex_bd">
                                    <h3>{{ lang=='zh-cn'?ite.ltitle:ite.ltitle_en }}</h3>
                                    <!-- <p>用心感受生活</p> -->
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div v-if="item.item_type == 'zhishi'" class="index-section index-style-g">
                <div class="container wow fadeInUp">
                    <div class="section-head text-center">
                        <div v-if="lang=='zh-cn'" class="h2">常见问题</div>
                        <div v-else class="h2">Frequently asked questions</div>
                    </div>
                    <div class="index-collapse">
                        <el-collapse v-model="activeNames" accordion>
                            <el-collapse-item v-for="(ite,idx) in item.content" :key="idx" :title="lang=='zh-cn'?ite.ltitle:ite.ltitle_en" :name="idx" >
                                <div v-html="lang=='zh-cn'?ite.desc:ite.desc_en"></div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="contact-cell wow fadeInDown">
                        <div class="contact-avatr">
                            <img src="../assets/image/index/NoPath1x.png" alt="" />
                        </div>
                        <div v-if="lang=='zh-cn'" class="flex_bd">
                            有其他问题吗？ <a href="">直接联系我们的客服</a>
                        </div>
                        <div v-else class="flex_bd">
                            Have more questions？ <a href="">Contact our customer service directly</a>
                        </div>
                    </div>
                </div>
            </div>	
        </div>	
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	name: 'Content',
    data() {
        return {
            // pageitems: [],
            swiperOptionThumbs: {
                loop: true,
                // autoplay: 3000,
                // autoplay: true,
                // loopedSlides: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper--next',
                    prevEl: '.swiper--prev'
                }
            },
            tavName:'',
            activeNames: [0],
            kehuThumbs: {
                loop: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 20,
                navigation: {
                    nextEl: '.k--next',
                    prevEl: '.k--prev'
                }
            },
        }
    },
	props : {
        pagecontent      : { type : Array,  default : new Array([]) }
    },
    computed:{
        ...mapState({
            'lang':state=>state.app.currentLang
        }),
        pageitems(){
            var itemlist=this.pagecontent.filter(ite=>ite.item_type !='slideshow')
            var items = []
            var ob = {}
            var pic = []
            var title, ltitle, content, titile_comment
            for (let it of itemlist){                
                if(it.content){
                    let acontent = JSON.parse(it.content)
                    if(!Array.isArray(acontent)){
                        content = [{desc: acontent}]
                    }else{
                        content = acontent
                    }
                }
                if(it.pictures && it.pictures.length>0){
                    pic=it.pictures.split(',')
                }
                title = this.lang == 'en-US'?it.title_en :it.title
                titile_comment = this.lang == 'en-US'?it.titile_comment_en :it.titile_comment
                ob = {title:title,ltitle:ltitle,titile_comment:titile_comment,pic:pic,item_type:it.item_type,content:content}
                items.push(ob)
            }
            return items
        },
    },
    mounted() {
        new this.$wow.WOW({live: false}).init();
    },
    methods:{
        clickit(pid){
			this.$router.push({ path: '/ProductDetail?prod_id='+pid})
		},
    }
}
</script>
<style scoped>
	.videoc{width:80vw;}	
</style>
































