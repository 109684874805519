<template>
	<div class="footer">
		<div class="container ">
			<div class="foot-main flex">
				<div class="foot-left">
					<div class="foot-logo flex-ac">
						<img :src="foot_logo" alt="">
					</div>
					<div class="foot-ipc">
						<img src="../assets/image/index/icon-iso@1x.png" alt="" />
						<img src="../assets/image/index/icon-fsc@1x.png" alt="" />
					</div>
				</div>
				<div class="foot-body flex flex_bd">
					<div class="foot-link">
						<ul class="foot-nav-link">
							<li v-for="(item,key) in menus" :key="key" ><router-link :to="item.url">{{ item.name }}</router-link></li>
							<!-- <li><router-link to="/">产品介绍</router-link></li>
							<li><router-link to="/">关于我们</router-link></li> -->
						</ul>
					</div>
					<div v-if="lang=='zh-cn'"  class="foot-link">
						<ul >
							<li>联系我们</li>
							<li><span>邮箱：</span>{{ company.email }}</li>
							<li><span>地址：</span>{{ company.address }}</li>
						</ul>
					</div>
					<div v-else  class="foot-link">
						<ul >
							<li>ContactUs</li>
							<li><span>email：</span>{{ company.email }}</li>
							<li><span>address:</span>{{ company.address_en }}</li>
						</ul>
					</div>
				</div>
				<div class="foot-wx">
					<!-- <img src="../assets/image/erweima.jpg" alt=""> -->
					<p>关注我们</p>
				</div>
			</div>
			<div class="copyright-end">
				<div class="copyright-cell">
					<!-- <img src="../assets/image/an.png" alt="" /> -->
					<!-- <a href="">此处为公司备案号</a> 
					<span class="line">|</span> -->

				</div>
				Copyright ©{{ company.enter_name }}  2024版权所有
				<div class="lock-box">
					<img src="../assets/image/index/lock.png" alt="" /><span>100%安全交易</span>
				</div>
				<div class="foot-icons">
					<img src="../assets/image/index/f1.png" alt="" />
					<img src="../assets/image/index/f2.png" alt="" />
					<img src="../assets/image/index/f3.png" alt="" />
					<img src="../assets/image/index/f4.png" alt="" />
					<img src="../assets/image/index/f5.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	name: 'Foot',
	computed:{
		...mapState({
			'lang':state=>state.app.currentLang,
			'webcontents':state=>state.app.webcontents
		}),		
		foot_logo(){
			if(this.webcontents && this.webcontents.base && this.webcontents.base.logo){
				return this.webcontents.base.foot_logo
			}else{
				return '' //修改公司默认LOGO
			}
		},
		company(){
			if(this.webcontents){
				return this.webcontents.base
			}else{
				return {}
			}
			// return this.webcontents.base
		},
		menus(){
			if(this.lang == "zh-cn"){
				var cnmenu = this.webcontents ? this.webcontents.list.filter(item=>item.pagename_zh != null && item.is_activated):[]
				for(var ite of cnmenu){
					ite.name= ite.pagename_zh;
					ite.url= "/"+ite.type+"/";
				}
				return cnmenu;
			}else{
				var enmenu = this.webcontents?this.webcontents.list.filter(item=>item.pagename_en != null && item.is_activated):[]
				for(var itm of enmenu){
					itm.name= itm.pagename_en;
					itm.url= "/"+itm.type+"/";
				}
				return enmenu;
			}
		}
	},
}
</script>
<style scoped>
	.foot-link{
		margin-right: 180px;
	}
	.foot-link:last-child{
		margin-right: 0;
	}
	.foot-link ul li{
		margin-bottom: 25px;
	}
	.foot-link ul li:last-child{
		margin-bottom: 0;
	}
	.foot-link ul li a:hover{
		opacity: .7;
	}
	.foot-link ul li span{
		color: #666666;
	}
	.foot-wx{
		text-align: center;
	}
	.foot-wx p{
		margin-top: 10px;
		color: #666666;
	}
	.foot-left{
		margin-right: 140px;
	}
	.foot-text{
		font-weight: bold;
		font-size: 20px;
	}
	.footer{
		background-color: #000000;
		color: #ffffff;
	}
	.foot-main{
		padding: 80px 0 40px 0;
	}
	.copyright{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		padding: 30px 0;
		color: #666666;
		border-top: 1px solid rgba(255, 255, 255, .1);
	}
	.copyright .line{
		padding: 0 15px;
	}
	.copyright img{
		margin-right: 15px;
	}
	.foot-logo{
		margin-bottom: 30px;
	}
	.foot-ipc{
		display: flex;
		align-items: center;
	}
	.foot-ipc img{
		margin-right: 20px;
	}
	.copyright-cell{
		display: inline-flex;
		align-items: center;
		line-height: 1;
		font-size: 12px;
		font-weight: normal;
		line-height: 12px;
	}
	.copyright-cell img{
		margin-right: 10px;
	}
	.copyright-end{
		display: flex;
		align-items: center;
		padding-top: 30px;
		padding-bottom: 20px;
		border-top: 1px solid #4B4B4B;
		color: #6A6C6D;
	}
	.foot-icons{
		margin-left: 130px
	}
	.foot-icons img{
		width: 43px;
		margin-left: 10px;
	}
	.lock-box{
		margin-left: auto;
		display: flex;
		align-items: center;
	}
	.lock-box img{
		margin-right: 10px;
	}
	
	@media (max-width:1200px) {
		.foot-left{
			margin-right: 70px;
		}
		.foot-link{
			margin-right: 90px;
		}
	}
	
	@media (max-width:1000px) {
		.foot-main{
			flex-wrap: wrap;
		}
		.foot-left{
			width: 100%;
			margin-bottom: 30px;
			margin-right: 0;
		}
		.foot-main .flex_bd{
			display: block;
			width: 100%;
			flex: unset;
		}
		.foot-logo{
			margin-bottom: 10px;
		}
		.foot-link{
			width: 100%;
			margin-right: 0;
		}
		.foot-wx{
			width: 100%;
			text-align: center;
			margin-top: 30px;
		}
		.foot-link ul li{
			margin-bottom: 15px;
		}
		.foot-nav-link{
			display: flex;
			padding-bottom: 20px;
		}
		.foot-nav-link li{
			width: 33.33%;
		}
		.copyright{
			font-size: 14px;
			line-height: 2;
		}
		
	}
	
	
	
	
	
	
	
	
	
	
	
</style>
































