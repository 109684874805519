<template>
	<div class="page-container">
		<Head />
		<div class="index-banner">
			<swiper class="swiper gallery-banner" :options="swiperOptionThumbs" ref="swiperThumbs">
				<!-- <swiper-slide> -->
				<swiper-slide v-for="(item,index) in slidePic.length" :key='index'>
					<div class="banner-item" :style="{backgroundImage:'url('+slidePic[index]+')'}">
						<div class="container">
							<div class="banner-grid wow fadeInDown">
								<div class="text-lg">{{slide.title}}</div>
								<div class="text-sm">{{slide.titile_comment}}</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<!-- <div class="swiper-pagination" slot="pagination"></div>
			<div class="swiper--next" slot="button-next">
			</div>
			<div class="swiper--prev" slot="button-prev">
			</div> -->
		</div>
		<div class="page-wrapper">
			<Content v-if="!!thispage" :pagecontent="thispage.items"/>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	// @ts-ignore
	import Head from '@/components/Head.vue'
	// @ts-ignore
	import Foot from '@/components/Foot.vue'
	import Content from '../components/content.vue';
	import { mapState, mapMutations} from 'vuex';
	
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			Content
		},
		data() {
			return {
				watch_new: false,
				thispage:null,
				banner:require('../assets/image/banner-@1x.jpg'),
				swiperOptionThumbs: {
					loop: true,
					// autoplay: 3000,
					autoplay: true,
					// loopedSlides: 1,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper--next',
						prevEl: '.swiper--prev'
					}
				},
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current,
				'en_id':state=>state.app.en_id
			}),
			slide(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0]:'';
			},
			slidePic(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0].pictures.split(','):[];
			},			
		},
		created(){
			this.getDataFromServer()
		},
		methods: {
			...mapMutations({
				setCurrent(commit, current) {
					commit('app/SET_CURRENT', current)
				},
				setWebInfo(commit, webcontent) {
					commit('app/SET_WEB', webcontent)
				},
			}),
			getDataFromServer(){
				this.$axios.get("https://jesway.cn/api/website/all_websites?en_id="+this.en_id).then(res=>{
					// this.$axios.get("http://localhost:8999/api/website/all_websites?en_id="+this.en_id).then(res=>{
					this.setWebInfo(res.data)
					// console.log(JSON.stringify(this.webcontents))	
					this.watch_new = !this.watch_new
					this.getData()
				})
			},
			getData(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'Home')[0].id
					this.setCurrent(vid)
					this.thispage = this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					this.thispage = this.webcontents.list.filter(item=>item.id == this.current )[0]
				}
				document.title = this.lang == 'en-US' ? this.webcontents.base.en_name : this.webcontents.base.enter_name 
			},
			buttonEevent1(url){
				if('http' in url){
					window.open(url, '_blank')
				}else{
					this.$router.push({ path:url})
				}
			}
		},
		mounted() {
			new this.$wow.WOW({live: false}).init();
		}
	}
</script>
